import Grid from "@mui/material/Grid";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { Fragment, useEffect, useRef, useState } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import useStyles from "./style";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import theme from "../../Paleta/theme";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

export default function CampoDocumento({
  doc,
  setData,
  isLoading,
  handlerDeleteImage,
}) {
  const [formData, setFormData] = useState(doc);
  const classes = useStyles();
  const imageInputRef = useRef(null);
  const [isHoverDrag, setISHoverDrag] = useState(false);

  const handlerChangeDoc = (event) => {
    let file = null;

    if (!!event?.dataTransfer) {
      file = event.dataTransfer.files[0];

      // let images = files.map(file =>{
      //   const {name, size} = file;

      //   const reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   reader.onloadend = () => {
      //     const preview = reader.result;
      //     const image = {path: preview, desc:""};
      //     setImgSelected((prevState) => ({...image}));
      //   }

      // });
    } else file = event.target.files[0];

    // transforma em base 64
    getBase64(file, async (result) => {
      setFormData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });

      setData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });
    });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const handlerDeleteDoc = (documento) => () => {
    let editDoc = {
      ...documento,
      nomeArquivo: null,
      contentType: null,
      base64: null,
      tamanho: 0,
    };

    setFormData(() => ({ ...editDoc }));

    if (imageInputRef) imageInputRef.current.value = "";

    handlerDeleteImage(editDoc);
  };

  const preventBrowserDefault = (e) => {
    e.preventDefault();
  };

  const changeHoverDrag = (value) => {
    setISHoverDrag(() => value);
  };

  const eventsDrag = {
    onDragEnter: (e) => {
      preventBrowserDefault(e);
      changeHoverDrag(true);
    },
    onDragOver: (e) => {
      preventBrowserDefault(e);
      changeHoverDrag(true);
    },
    onDragLeave: (e) => {
      preventBrowserDefault(e);
      changeHoverDrag(false);
    },
    onDrop: (e) => {
      preventBrowserDefault(e);
      handlerChangeDoc(e);
      changeHoverDrag(false);
    },
  };

  return (
    <Grid container sx={{ padding: "0px 10px" }}>
      <Grid
        item
        className={classes.Item}
        xs={12}
        lg={12}
        mr={1}
        onClick={handlerChangeDoc}
        mt={1}
        {...eventsDrag}
      >
        {isLoading ? (
          <Skeleton animation="wave" width={300} height={40} sx={{ m: 2 }} />
        ) : (
          <Fragment>
            <Box
              sx={{
                p: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: 2,
                }}
              >
                {formData.tamanho > 0 && (
                  <>
                    <Button
                      variant="text"
                      sx={{ p: 0, minWidth: 0 }}
                      onClick={handlerDeleteDoc(formData)}
                    >
                      <DeleteIcon sx={{ mb: 1, fontSize: 20 }} color="error" />
                    </Button>
                  </>
                )}
              </Box>

              {isHoverDrag ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="text"
                    sx={{
                      fontSize: 16,
                      alignItems: "center",
                      mb: 1,
                      opacity: 0.8,
                    }}
                  >
                    Solte o documento para adicionalo
                  </Typography>
                  <GetAppIcon
                    sx={{ mr: 1, color: `${theme.palette.primary.main}` }}
                    fontSize="large"
                  />
                </Box>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    color="text"
                    sx={{
                      textTransform: "inherit",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "600",
                    }}
                  >
                    {doc.obrigatorio
                      ? doc.tipoDocumento + " (obrigatório)"
                      : doc.tipoDocumento}
                    {formData.tamanho > 0 && (
                      <>
                        <DoneIcon sx={{ ml: 2, fontSize: 15 }} />
                      </>
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text"
                    sx={{
                      fontSize: 12,
                      alignItems: "center",
                      mb: 1,
                      display: "flex",
                      opacity: 0.8,
                      justifyContent: "center",
                    }}
                  >
                    Selecione o arquivo do seu dispositivo
                  </Typography>

                  <Button
                    sx={{
                      mt: 0,
                      m: 2,
                      textTransform: "capitalize",
                      margin: "auto",
                      justifyContent: "center",
                      display: "flex",
                      color: theme.palette.primary,
                    }}
                    component="label"
                    variant="label"
                    size="small"
                    onChange={handlerChangeDoc}
                  >
                    <GetAppIcon
                      sx={{ mr: 1, color: `${theme.palette.primary.main}` }}
                      fontSize="10"
                    />
                    <input
                      type="file"
                      ref={imageInputRef}
                      accept="*/*"
                      hidden
                    />
                    Selecionar
                  </Button>
                </>
              )}

              {!!formData.contentType && (
                <>
                  <Box className={classes.containerImg}>
                    {formData.contentType === "application/pdf" ? (
                      <>
                        <PictureAsPdfIcon fontSize="large" />
                      </>
                    ) : formData.contentType === "image/png" ||
                      formData.contentType === "image/jpeg" ||
                      formData.contentType === "image/webp" ? (
                      <img src={formData.base64} alt={doc.tipoDocumento} />
                    ) : (
                      <InsertDriveFileIcon fontSize="large" />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Fragment>
        )}
      </Grid>
      <Grid
        container
        xs={12}
        md={2}
        mt={1}
        lg={2}
        mr={1}
        sx={{ alignItems: "center", justifyContent: "flex-end" }}
      ></Grid>
    </Grid>
  );
}
