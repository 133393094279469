import api from "..";
import TiposOrigem from "../../util/typesTipoOrigem";

export const getLimitesDiponiveis = async (idCliente) => {
  try {
    const { status, data: response } = await api.get(
      `/limiteOperacional/GetByIDCliente?idCliente=${idCliente}&idOrigem=${TiposOrigem.lojaCambioWeb}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " 12AA1");
  }
};

export const getPerfisLimiteOperacional = async (idCliente) => {
  try {
    const { status, data: response } = await api.get(
      `/PerfilLimiteOperacional/GetByIDCliente?idCliente=${idCliente}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " 1D9C5");
  }
};

export const getAllLimiteOperacional = async (idTipoCliente) => {
  try {
    const { status, data: response } = await api.get(
      `/PerfilLimiteOperacional/GetByIDTipoCliente?idTipoCliente=${idTipoCliente}&idOrigem=${TiposOrigem.lojaCambioWeb}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + "6565");
  }
};

export const getLimitesPerfilLimiteOperacional = async (idCliente) => {
  try {
    const { status, data: response } = await api.get(
      `/PerfilLimiteOperacional/GetProximosByIDCliente?idCliente=${idCliente}&idOrigem=${TiposOrigem.lojaCambioWeb}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " 1F7C4");
  }
};

export const getExistProximoNivelPerfisLimiteOperacional = async (
  idCliente
) => {
  try {
    const { data: response } = await api.get(
      `/PerfilLimiteOperacional/ExistProximoNivelPerfisLimiteOperacional?idCliente=${idCliente}&idOrigem=${TiposOrigem.lojaCambioWeb}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " 1C5B9");
  }
};

export const getCurrentPerfilLimiteOperacional = async (
  idPerfilLimiteOperacional
) => {
  try {
    const { status, data: response } = await api.get(
      `/PerfilLimiteOperacional/Get?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " DDAD");
  }
};
