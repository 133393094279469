import { useState } from "react";
import CampoDocumento from "../../Campos/CampoDocumento";
import Subtitulo from "../../Textos/Subtitulo";
import useStyle from "./styles";

export default function Arquivo({ setDados, items }) {
  const [documentos, setDocumentos] = useState(items);
  const classes = useStyle();

  const handlerChange = (doc) => {
    console.log("handlerChange doc", doc);

    const newValue = documentos.map((d) => {
      if (d.idTipoDocumento === doc.idTipoDocumento) {
        return { ...doc };
      }

      return { ...d };
    });

    setDocumentos(newValue);
    setDados(newValue);
  };

  const handlerDeleteImage = (doc) => {
    if (doc) {
      let newDoc = documentos.map((d) => {
        if (d.tipoDocumento === doc.tipoDocumento) {
          d = doc;
        }
        return d;
      });

      setDocumentos((prev) => [...newDoc]);
      setDados(newDoc);
    }
  };

  console.log("Arquivo documentos", documentos);
  console.log("Arquivo items", items);

  return (
    <>
      <Subtitulo
        sx={{ ml: 3, mt: 3, mb: 1, width: "100%" }}
        color="primary"
        label="Anexo de Documentos"
      />
      <div style={{ marginLeft: 0, display: "block" }}>
        {documentos &&
          documentos.map((d, index) => (
            <CampoDocumento
              key={index}
              doc={d}
              handlerDeleteImage={handlerDeleteImage}
              setData={handlerChange}
              isLoading={false}
            />
          ))}
      </div>
    </>
  );
}
