import { useEffect } from "react";
import { getSocios } from "../../../api/Cadastro";
import SocioForm from "./SocioForm";
import { Skeleton } from "@mui/material";

export default function SocioItem({
  socios,
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const initSocios = async (idCliente, idPerfilLimiteOperacional) => {
    try {
      let response = await getSocios(idCliente, idPerfilLimiteOperacional);

      if (response.length > 0) setDada(response);
    } catch (error) {
      console.error("Response vazio ou inválido", error.message);
    }
  };

  useEffect(() => {
    initSocios(idCliente, idPerfilLimiteOperacional);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          {Array.from({ length: 2 }, () => (
            <Skeleton animation="wave" height={80} />
          ))}
        </>
      ) : (
        <SocioForm
          identificaItem="Sócio"
          socios={socios}
          setSocios={setDada}
          title="Sócio(s)"
          tooltipAdd="Adicione um sócio"
          tooltipDelete="Remove o sócio selecionado"
          idPerfilLimiteOperacional={idPerfilLimiteOperacional}
          idCliente={idCliente}
        />
      )}
    </>
  );
}
